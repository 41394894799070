import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Settings, Wifi } from 'lucide-react';
import { CONFIGURATOR_URL } from '../../config/configurator';
import ConfiguratorTutorial from './ConfiguratorTutorial';
import ConfiguratorControls from './ConfiguratorControls';

export default function ConfiguratorPage() {
  const handleLaunchConfigurator = () => {
    window.open(CONFIGURATOR_URL, '_blank');
  };

  return (
    <div className="min-h-screen pt-32 pb-24">
      <Helmet>
        <title>Car Configurator | Kustom Auto Wrx</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">
            Interactive Car Configurator
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Design your dream vehicle with our advanced 3D configurator
          </p>
          <button 
            onClick={handleLaunchConfigurator}
            className="bg-blue-600 text-white px-8 py-4 rounded-xl font-semibold hover:bg-blue-700 transition-colors"
          >
            Launch Configurator
          </button>
        </div>

        <div className="bg-white/10 rounded-xl p-8 backdrop-blur-sm mb-12">
          <div className="flex items-start space-x-3 mb-6">
            <div className="p-2 bg-blue-600/20 rounded-lg shrink-0">
              <Settings className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Quality Settings
              </h3>
              <p className="text-gray-300">
                If you experience slower performance, you can adjust the visual quality to match your internet connection. Click the gear icon <Settings className="w-4 h-4 inline text-gray-400" /> in the top-right corner of the configurator to access quality settings:
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white/5 rounded-lg p-4">
              <div className="flex items-center space-x-2 mb-2">
                <Wifi className="w-5 h-5 text-blue-400" />
                <h4 className="font-semibold text-white">Epic Quality</h4>
              </div>
              <p className="text-sm text-gray-400">Best visual quality for fast connections (10+ Mbps)</p>
            </div>
            <div className="bg-white/5 rounded-lg p-4">
              <div className="flex items-center space-x-2 mb-2">
                <Wifi className="w-5 h-5 text-blue-400" />
                <h4 className="font-semibold text-white">High Quality</h4>
              </div>
              <p className="text-sm text-gray-400">Great balance of quality and performance (6+ Mbps)</p>
            </div>
            <div className="bg-white/5 rounded-lg p-4">
              <div className="flex items-center space-x-2 mb-2">
                <Wifi className="w-5 h-5 text-blue-400" />
                <h4 className="font-semibold text-white">Medium Quality</h4>
              </div>
              <p className="text-sm text-gray-400">Smoother performance for slower connections (4+ Mbps)</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <ConfiguratorTutorial />
          <ConfiguratorControls />
        </div>
      </div>
    </div>
  );
}