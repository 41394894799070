// Analytics Configuration
const GA_ID = 'G-NMYT2QSKGB';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

let isInitialized = false;

export function initializeAnalytics() {
  if (isInitialized || typeof window === 'undefined') return;
  
  try {
    const s1 = document.createElement('script');
    s1.async = true;
    s1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    
    const s2 = document.createElement('script');
    s2.textContent = `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${GA_ID}');`;
    
    document.head.appendChild(s1);
    document.head.appendChild(s2);
    
    isInitialized = true;
  } catch (e) {
    // Silently handle errors
  }
}

export function track(path: string) {
  try {
    window.gtag?.('event', 'page_view', { page_path: path });
  } catch (e) {
    // Silently handle errors
  }
}

export function event(category: string, action: string, label?: string) {
  try {
    window.gtag?.('event', action, { event_category: category, event_label: label });
  } catch (e) {
    // Silently handle errors
  }
}