import React from 'react';
import { MousePointer, Maximize2, RotateCw, Move } from 'lucide-react';

export default function ConfiguratorControls() {
  return (
    <div className="bg-white/10 rounded-xl p-8 backdrop-blur-sm">
      <h3 className="text-xl font-semibold text-white mb-6">
        Quick Reference Guide
      </h3>
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-medium text-white mb-3">Navigation Controls</h4>
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-blue-600/20 rounded-lg">
                <MousePointer className="w-5 h-5 text-blue-400" />
              </div>
              <span className="text-gray-300">Left click + drag to rotate view</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-blue-600/20 rounded-lg">
                <Move className="w-5 h-5 text-blue-400" />
              </div>
              <span className="text-gray-300">Right click + drag to pan</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-blue-600/20 rounded-lg">
                <Maximize2 className="w-5 h-5 text-blue-400" />
              </div>
              <span className="text-gray-300">Mouse wheel to zoom in/out</span>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-medium text-white mb-3">Tips</h4>
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-blue-600/20 rounded-lg">
                <RotateCw className="w-5 h-5 text-blue-400" />
              </div>
              <span className="text-gray-300">Double click to focus on parts</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}