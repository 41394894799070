import React from 'react';
import { event } from '../../lib/analytics/google-analytics';
import NavigationItem from './NavigationItem';

interface NavigationItemsProps {
  currentPage: string;
  isMobile: boolean;
  onPageChange: (page: string) => void;
  onMobileClose?: () => void;
}

export default function NavigationItems({ 
  currentPage, 
  isMobile, 
  onPageChange,
  onMobileClose 
}: NavigationItemsProps) {
  const handlePageChange = (page: string) => {
    onPageChange(page);
    // Track navigation silently
    requestAnimationFrame(() => {
      event('navigation', 'page_change', page);
    });
  };

  const pages = [
    'home',
    'services',
    'wrap-brands',
    'configurator',
    'pricing',
    'car-care',
    'gallery',
    'faq',
    'faqai',
    'about',
    'contact',
    'privacy'
  ];

  return (
    <>
      {pages.map((page) => (
        <NavigationItem
          key={page}
          item={page}
          currentPage={currentPage}
          isMobile={isMobile}
          onPageChange={handlePageChange}
          onMobileClose={onMobileClose}
        />
      ))}
    </>
  );
}