import React from 'react';
import { Play } from 'lucide-react';

export default function ConfiguratorTutorial() {
  return (
    <div className="bg-white/10 rounded-xl overflow-hidden backdrop-blur-sm">
      <div className="relative aspect-video">
        <iframe
          src="https://www.youtube.com/embed/5LVga5CaGts"
          className="w-full h-full"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold text-white mb-2">
          How to Use the Configurator
        </h3>
        <p className="text-gray-300 mb-4">
          Watch our quick tutorial to learn how to get the most out of our interactive car configurator.
        </p>
        <div className="space-y-2">
          <div className="flex items-center space-x-2 text-gray-300">
            <Play className="w-4 h-4 text-blue-400" />
            <span>Learn basic navigation and controls</span>
          </div>
          <div className="flex items-center space-x-2 text-gray-300">
            <Play className="w-4 h-4 text-blue-400" />
            <span>Explore color and finish options</span>
          </div>
          <div className="flex items-center space-x-2 text-gray-300">
            <Play className="w-4 h-4 text-blue-400" />
            <span>Customize wheels and accessories</span>
          </div>
        </div>
      </div>
    </div>
  );
}