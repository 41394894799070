export const CONFIGURATOR_URL = 'https://larkxr-hk.paraverse.cc:8443/webclient/?appliId=1313124541794353152&codeRate=10000&frameRate=60&language=en';

export const QUALITY_SETTINGS = {
  epic: {
    codeRate: 10000,
    frameRate: 60
  },
  high: {
    codeRate: 6000,
    frameRate: 60
  },
  medium: {
    codeRate: 4000,
    frameRate: 30
  }
} as const;

export function buildConfiguratorUrl(quality: keyof typeof QUALITY_SETTINGS = 'epic'): string {
  const settings = QUALITY_SETTINGS[quality];
  const url = new URL(CONFIGURATOR_URL);
  url.searchParams.set('codeRate', settings.codeRate.toString());
  url.searchParams.set('frameRate', settings.frameRate.toString());
  return url.toString();
}